.star-rating {
  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .on {
    color: #000;
  }
  .off {
    color: #ccc;
  }
}

.star-display {
  display: flex;
  .star-stack {
    position: relative;
    margin-right: 4px;
    .star-stack-active {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
    }
  }
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
}
.star-line {
}
