$primary-color: #dc3571;

.button-common {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: none;
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Inter', sans-serif;
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: inherit;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 4px 8px var(--primary-color);
  }
  &:active::after {
    box-shadow: 0 0 0 0 white;
    position: absolute;
    border-radius: inherit;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
  }
  &:hover {
    opacity: 0.7;
  }
  .rotate {
    margin-right: 7px;
    animation: loading 0.5s linear infinite;
    display: none;
  }
  &.loading {
    opacity: 0.7;
    pointer-events: none;
    cursor: wait;
    .rotate {
      display: block;
    }
  }
  &.outline {
    background: #fff;
    color: var(--primary-color);
  }
  &.simple {
    background: #fff;
    color: #181818;
    border: 1px solid #e8e8e8;
  }
  &.text {
    background: none;
    color: var(--primary-color);
    border: none;
    &:after {
      content: '';
      display: none;
    }
    &:hover {
      background-color: inherit !important;
    }
    svg {
      fill: var(--primary-color);
    }
  }
  &.disable {
    pointer-events: none;
    cursor: not-allowed;
    background: #c1c1c1;
    border-color: #c1c1c1;
  }
}

.size {
  &-default {
    padding: 8px 20px;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    svg {
      margin-right: 10px;
    }
  }
  &-small {
    padding: 6px 18px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    svg {
      margin-right: 10px;
    }
  }
  &-large {
    padding: 10px 22px;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    svg {
      margin-right: 10px;
    }
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
