@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter/Inter-Thin.ttf");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter/Inter-ExtraLight.ttf");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter/Inter-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter/Inter-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter/Inter-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter/Inter-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter/Inter-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// @font-face {
//   font-family: "Inter";
//   src: url("/fonts/Inter/Inter-ExtraBold.ttf");
//   font-weight: 800;
// }

// @font-face {
//   font-family: "Inter";
//   src: url("/fonts/Inter/Inter-Black.ttf");
//   font-weight: 900;
// }
