.card {
  box-shadow: 0px 4px 8px 4px rgba(199, 205, 231, 0.25);
  .mark-tag {
    background: linear-gradient(90deg, #b02a5a 0%, #dc3571 100%);
    padding: 4px 8px;
    border-radius: 0px 4px 4px 0px;
  }
  .orai {
    background: linear-gradient(90deg, #b02a5a 0%, #dc3571 100%);
  }
  .dola {
    color: #dc3571;
  }
  .popup {
    animation: open 0.3s ease-in-out;
    @keyframes open {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
  :global {
    .dropdown-trigger + div {
      z-index: 10;
      width: 220px;
    }
  }
}
