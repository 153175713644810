.select {
  .content {
    box-shadow: 0px 74px 30px rgba(95, 104, 123, 0.01),
      0px 42px 25px rgba(95, 104, 123, 0.05),
      0px 19px 19px rgba(95, 104, 123, 0.08),
      0px 5px 10px rgba(95, 104, 123, 0.09), 0px 0px 0px rgba(95, 104, 123, 0.1);
    /* width */
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f8f9fd;
      border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #ccd1e0;
      border-radius: 4px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
