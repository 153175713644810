.login-container {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  font-size: 16px;
  line-height: 1.2;
  font-family: 'Inter', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);

  .cover-box {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    min-height: calc(100vh - 200px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    .row {
      position: relative;
      z-index: 2;
    }

    .left-box {
      background-color: rgba(255, 255, 255, 0.7);
      padding: 2rem 3rem;
      backdrop-filter: blur(50px);
      -webkit-backdrop-filter: blur(50px);
      z-index: 999;

      .title {
        margin-top: 2rem;
        font-size: 2rem;
        font-weight: bold;
      }

      .content {
        font-size: 1em;
        font-weight: normal;
        color: #999999;
        margin: 1rem 0;
        margin-bottom: 3rem;
        max-width: 400px;
      }

      .upside {
        margin-top: 3rem;

        .title {
          font-size: 1.5rem;
          font-weight: normal;
        }
      }
    }

    .right-box {
      padding: 2rem 3rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .card-over {
        background-color: rgba(255, 255, 255, 0.8);
        max-width: 100%;
        min-height: 250px;
        border-radius: 24px;
        padding: 2rem;
        width: 510px;
        button {
          margin-top: 20px;
          width: 100%;
          margin-bottom: 10px;
        }

        .title {
          font-size: 1.5rem;
          margin-bottom: 10px;
          font-weight: bold;
          line-height: 1.1;
          color: rgb(63, 62, 62);
        }

        .note {
          margin: 16px 0px 24px 0px;
          color: #bbbbbb;
          font-size: 14px;
          line-height: 1.4;
        }
      }
    }
  }

  .ocean {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: -40px;
    left: 0;
    z-index: 1;
    /* background: #015871; */
  }

  .cover-png {
    background: url('https://gateway.ipfs.airight.io/ipfs/QmXQKuYUBsWzs35d1MyMGXJ6Kfit9irktqsjiCG9vLUhqs');
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    background-size: cover;
    z-index: 0;
  }

  .wave {
    background: url('https://gateway.ipfs.airight.io/ipfs/QmY4nyMTJbBbugN642ZZRm6VZw3sDqicEBw2YHM9xrXrZo') repeat-x;
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    animation: wave 14s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
  }

  .wave:nth-of-type(2) {
    top: -175px;
    animation: wave 21s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
    opacity: 1;
  }

  .endWave {
    display: none;
  }

  iframe {
    border: none;
    width: 100%;
    height: 200px;
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

//Responsive

@media (max-width: 991px) {
  .login-container {
    .ocean {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .login-container {
    box-shadow: none;
    .ocean {
      display: none;
    }
    .cover-box {
      .left-box {
        backdrop-filter: none;
        padding: 20px;
      }
    }
  }
}
