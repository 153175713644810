.common-layout {
  .main-container {
    max-width: 1470px;
    margin-top: 65px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .main {
    display: flex;
    margin: 0 -15px;
    .content {
      width: calc(100% - 280px);
      padding: 40px 15px;
      transition: all 0.3s ease-in-out;
      &.sidebar-close {
        width: calc(100% - 80px);
      }
    }
  }
}
