.flex-container {
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
    color: #181818;
    font-weight: 400;
  }
  svg {
    margin-right: 6px;
  }
}
