.loading-page {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  top: 20px;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 99999;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-in-out;
  box-shadow: 0px 4px 4px 0px #d6e0ef40;
}

.loading-page-wrapper {
  width: 100vw;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.show {
  opacity: 1;
  visibility: visible;
  top: 28px;
}
