.switch {
  width: 44px;
  height: 22px;
}
.react-switch-checkbox {
  display: none;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: inherit;
  height: inherit;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  width: 40%;
  height: 83%;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(55%);
}
