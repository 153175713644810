.modal-keplr {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .content {
    background: #fff;
    padding: 30px 30px;
    position: relative;
    max-width: calc(100% - 30px);
    border-radius: 6px;
    .head {
      display: flex;
      color: #181818;
      font-size: 16px;
      .icon {
        font-size: 20px;
        margin-right: 6px;
      }
    }
    .footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
      button {
        color: #fff;
        font-weight: 500;
        height: 36px;
        width: 100px;
        border-radius: 4px;
        cursor: pointer;
        outline: none;
        box-shadow: none;
        border: none;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
