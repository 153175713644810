.noti {
  margin-right: 25px;
  &-box {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 4px rgba(192, 176, 177, 0.25);
    border-radius: 50%;
    &-warning {
      svg {
        font-size: 18px;
        color: #fbbf24;
        position: absolute;
        bottom: 0;
        right: -4px;
      }
    }
  }
  &-head {
    padding: 10px 15px;
    border-bottom: 1px solid #ececec;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .read-all {
      display: flex;
      align-items: center;
      font-weight: 400;
      cursor: pointer;
      svg {
        margin-right: 4px;
      }
    }
  }
  &-list {
    height: 350px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #9c9c9c;
      border-radius: 4px;
    }
  }
  &-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
    span {
      font-size: 14px;
      margin-top: 7px;
    }
  }
  &-content {
    border-radius: 5px;
    width: 370px;
    max-width: 100vw;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    .noti-request-permission {
      padding: 12px 16px;
      background: #fff9e9;
      display: flex;
      justify-content: end;
      align-items: flex-end;
      font-size: 13px;
      display: flex;
      align-items: center;

      button {
        margin-top: 12px;
      }

      svg {
        font-size: 45px;
        color: #fbbf24;
        margin-right: 16px;
      }
    }
  }
  &-item {
    display: flex;
    padding: 15px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
    .not-read {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #0276fd;
    }
    &:hover {
      background: rgba(2, 118, 253, 0.08);
    }
    .item-left {
      width: 55px;
      .box-avatar {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        position: relative;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
        .icon {
          position: absolute;
          bottom: 0px;
          right: 0;
          width: 16px;
          height: 16px;
          background: #000;
          border-radius: 50%;
          > img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }
    }
    .item-right {
      width: calc(100% - 55px);
      padding-right: 8px;
      .date {
        color: #747c85;
        font-size: 13px;
      }
    }
  }
  &-has {
    position: absolute;
    top: -1px;
    right: -3px;
    width: 17px;
    height: 17px;
    background: red;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-size: 10px;
  }
}
.contact {
  &-order {
    border: 1px solid #e6e9f1;
    border-radius: 12px;
    padding: 20px;
    &-box {
      border-bottom: 1px dashed #e6e9f1;
      margin-bottom: 16px;
      padding-bottom: 16px;
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
      .field {
        font-size: 14px;
        color: #738088;
      }
      .content {
        color: #000;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
  &-message {
    margin-top: 20px;
    > p {
      margin-bottom: 7px;
      color: #b2b2b2;
      font-weight: 400;
      font-size: 14px;
    }
    textarea {
      border: 1px solid #e6e9f1;
      border-radius: 8px;
      height: 180px;
      width: 100%;
      padding: 12px 16px;
      color: #333;
      font-weight: 400;
      outline: none;
      box-shadow: none;
      &::placeholder {
        color: #949494;
      }
    }
  }
}
