@import 'font-face';

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #181818;
  font-style: normal;
  background: #f6f7fb;
  height: auto;
}
*,
*:before,
*::after {
  box-sizing: border-box;
}
img {
  max-width: 100%;
}
.react-calendar-heatmap .color-empty {
  fill: #e8e8e8;
}
.react-calendar-heatmap .color-1 {
  fill: #b1d2fc;
}
.react-calendar-heatmap .color-2 {
  fill: #83b9ff;
}
.react-calendar-heatmap .color-3 {
  fill: #60a5ff;
}
.react-calendar-heatmap .color-4 {
  fill: #3f92ff;
}
.react-calendar-heatmap .color-full {
  fill: #3f92ff;
}
.react-calendar-heatmap text {
  font-size: 12px;
  fill: #b2b2b2;
}

// font
$weights: (100, 200, 300, 400, 500, 600, 700);
@each $i in $weights {
  .fw-#{$i} {
    font-weight: 1 * $i !important;
  }
}
@for $i from 0 through 100 {
  .fz-#{$i} {
    font-size: 1px * $i !important;
  }
}
.Archivo {
  font-family: 'Archivo';
}

// margin padding
@for $i from 0 through 100 {
  .ma-#{$i} {
    margin: #{$i}px;
  }
  .pa-#{$i} {
    padding: #{$i}px;
  }
}
@each $abbr, $name in ('t': 'top', 'r': 'right', 'b': 'bottom', 'l': 'left') {
  @for $i from 0 through 100 {
    .m#{$abbr}-#{$i} {
      margin-#{$name}: 1px * $i !important;
    }
    .p#{$abbr}-#{$i} {
      padding-#{$name}: 1px * $i !important;
    }
  }
}

// width height
$amounts: (
  0,
  2,
  12,
  16,
  20,
  24,
  30,
  40,
  32,
  44,
  48,
  50,
  60,
  66,
  72,
  100,
  120,
  125,
  130,
  140,
  145,
  200,
  220,
  250,
  290,
  300,
  350,
  320,
  360,
  400,
  440,
  700,
  724
);
@each $space in $amounts {
  .w-#{$space} {
    width: #{$space}px !important;
  }
  .maw-#{$space} {
    max-width: #{$space}px !important;
  }
  .miw-#{$space} {
    min-width: #{$space}px !important;
  }
  .h-#{$space} {
    height: #{$space}px !important;
  }
  .mah-#{$space} {
    max-height: #{$space}px !important;
  }
  .mih#{$space} {
    min-height: #{$space}px !important;
  }
}
.h-full {
  height: 100% !important;
}
.w-full {
  width: 100% !important;
}

// posision
.pos-rel {
  position: relative;
}
.pos-abs {
  position: absolute;
}
@for $i from 0 through 100 {
  .top-#{$i} {
    top: 1px * $i !important;
  }
  .bottom-#{$i} {
    bottom: 1px * $i !important;
  }
  .right-#{$i} {
    right: 1px * $i !important;
  }
  .left-#{$i} {
    left: 1px * $i !important;
  }
}
.pos-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pos-center-y {
  top: 50%;
  transform: translateY(-50%);
}
.pos-center-x {
  left: 50%;
  transform: translateX(-50%);
}

// flex
.flex {
  display: flex !important;
}
.flex-none {
  display: none !important;
}
.flex-left {
  display: flex;
  justify-content: flex-start;
}
.flex-right {
  display: flex;
  justify-content: flex-end;
}
.flex-end {
  display: flex;
  align-items: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-align {
  display: flex;
  align-items: center;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-col-reverse {
  display: flex;
  flex-direction: column-reverse;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inline-block {
  display: inline-block !important;
}
.block {
  display: block !important;
}
.text-align {
  text-align: center !important;
}
.text-align-right {
  text-align: right;
}
.text-align-left {
  text-align: left;
}
.vertical-mid {
  vertical-align: middle;
}
.vertical-sub {
  vertical-align: sub;
}
//
.grid-5-5 {
  display: grid;
  grid-template-columns: 50% 50%;
}
.grid-4 {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

// border
@for $i from 0 through 100 {
  .bd-rds-#{$i} {
    border-radius: 1px * $i !important;
  }
}
.bder_text {
  border: 1px solid #e6e9f1;
}
.border-line {
  border: 1px solid #f0f2fa !important;
}
.border-solid {
  border: 1px solid #181818 !important;
}
.border-btn {
  border: 1px solid #dee1eb !important;
}
.border-none {
  border: none !important;
  outline: none !important;
}
.border-line-y {
  border-top: 1px solid #f0f2fa;
}
.border-line-x {
  border-right: 1px solid #f0f2fa;
}

//pointer
.pointer {
  cursor: pointer !important;
}

// text
.ellipsis {
  width: -webkit-fill-available;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@for $i from 0 through 20 {
  .ellipsis-#{$i} {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
  }
}
.cancel-ellipsis {
  width: -webkit-fill-available;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}
.break-word {
  word-wrap: break-word !important;
  max-width: 100% !important;
}
.text-blue {
  color: #3c8ff8 !important;
}
.text-danger {
  color: #e52828 !important;
}
.text-pink {
  color: #dc3571 !important;
}
.text-muted {
  color: #505665 !important;
}
.text-helper {
  color: #9da3b5 !important;
}
.text-info {
  color: #777e90 !important;
}
.text-gray {
  color: #616879 !important;
}
.text-white {
  color: #fff !important;
}
.text-black {
  color: #181818 !important;
}
.text-yellow {
  color: #cc9302 !important;
}
.text-success {
  color: #429d15 !important;
}

//background
.bg-muted {
  background-color: #f0f2fa !important;
}
.bg-danger {
  background-color: #e52828 !important;
}
.bg-pink {
  background-color: #dc3571 !important;
}
.bg-pink-sec {
  background-color: #fdeff4 !important;
}
.bg-secondary {
  background-color: #f8f9fd !important;
}
.bg-white {
  background-color: #fff;
}
.bg-waring {
  background-color: #fef2d3;
}
.bg-transparent {
  background-color: transparent !important;
}
.bg-yellow {
  background: #fff9e9 !important;
}
.bg-blue {
  background: #ecf4fe !important;
}
.bg-border {
  background: #dee1eb !important;
}
.bg-success {
  background: #eef9e8 !important;
}

////orther
/// scroll
.over-scroll-y {
  overflow: hidden;
  overflow-y: auto;
}

//Hide Arrows From Input Number
/* Chrome, Safari, Edge, Opera */
.disable-arrows,
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.disable-arrows,
input[type='number'] {
  -moz-appearance: textfield;
}

// z-index
.z-index-1 {
  z-index: 9;
}
.z-index-2 {
  z-index: 99;
}
.z-index-3 {
  z-index: 999;
}

// line-height
.lh-16 {
  line-height: 160% !important;
}
.lh-15 {
  line-height: 150% !important;
}
.lh-14 {
  line-height: 140% !important;
}
.lh-13 {
  line-height: 130% !important;
}
// opacity
.opacity07 {
  opacity: 0.7;
}

// hover
.hover-secondary {
  &:hover {
    background-color: #f8f9fd !important;
  }
}

// iamge
.object-fit-cv {
  object-fit: cover !important;
}
// typing
.ticontainer {
  width: 52px;
  height: 30px;
  background-color: #f0f2fa;
  border-radius: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  .tidot {
    background-color: #9da3b5;
  }
}
.tidot {
  animation: mercuryTypingAnimation 0.8s infinite ease-in-out;
  display: inline-block;
  border-radius: 5px;
  margin-right: 4px;
  height: 5px;
  width: 5px;
}
@keyframes mercuryTypingAnimation {
  0% {
    -webkit-transform: translateY(0px);
  }
  28% {
    -webkit-transform: translateY(-5px);
  }
  44% {
    -webkit-transform: translateY(0px);
  }
}
.tidot:nth-child(1) {
  -webkit-animation-delay: 100ms;
}
.tidot:nth-child(2) {
  -webkit-animation-delay: 200ms;
}
.tidot:nth-child(3) {
  -webkit-animation-delay: 300ms;
}
