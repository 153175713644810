//border
$modal-border-radius-default: 8px;

//font
$modal-title-fontsize-default: 16px;
$modal-title-fontweight-default: 600;
$modal-title-marginbottom-default: 16px;
$modal-desc-fontsize-default: 14px;
$modal-desc-lineheight-default: 1.4;
$modal-desc-fontweight-default: 400;

//footer
$modal-footer-margintop-default: 40px;
