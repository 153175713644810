.pagination {
  .content {
    box-shadow: 4px 6px 16px 8px rgba(199, 205, 231, 0.32);
    max-height: 120px;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: #f8f9fd;
    }
    &::-webkit-scrollbar-thumb {
      background: #dee1eb;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
