.widget-container {
  display: flex;
  justify-content: center;
  align-items: center;
  iframe {
    border: 0px;
  }
}

.widget-container.small {
  iframe {
    width: 160px;
    height: 50px;
  }
}

#__next {
  overflow: hidden;
}
