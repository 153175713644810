.coming-soon {
  position: relative;
  pointer-events: none;
  overflow: hidden;
  .coming-content {
    position: absolute;
    pointer-events: initial;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
  }
  .move {
    padding: 2px 5px;
    border-radius: 2px;
    position: fixed;
    display: none;
    color: #fff;
    font-size: 13px;
    background: rgba(0, 0, 0, 0.7);
    white-space: nowrap;
  }
}
