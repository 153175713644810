.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    animation: spin 1.2s infinite linear;
    position: relative;
    width: 28px;
    height: 28px;
    span {
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      background: #dc3571;
      border-radius: 50%;
      animation: spinMove 1s infinite linear alternate;
      opacity: 0.3;
      &:nth-child(1) {
        top: 0;
        left: 0;
      }
      &:nth-child(2) {
        top: 0;
        right: 0;
        animation-delay: 0.4s;
      }
      &:nth-child(3) {
        right: 0;
        bottom: 0;
        animation-delay: 0.8s;
      }
      &:nth-child(4) {
        left: 0;
        bottom: 0;
        animation-delay: 1.2s;
      }
    }
    &.small {
      width: 15px;
      height: 15px;
      span {
        width: 5px;
        height: 5px;
      }
    }
    &.large {
      width: 38px;
      height: 38px;
      span {
        width: 13px;
        height: 13px;
      }
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinMove {
  100% {
    opacity: 1;
  }
}
