.page-not-found {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 16px;
  .content {
    width: 100%;
    max-width: 597px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 100%;
      max-width: 392.6px;
    }
  }
  .title {
    font-weight: 700;
    font-style: normal;
    font-family: 'Archivo';
    font-size: 60px;
    line-height: 65px;
    margin: 60px 0 20px 0;
    text-align: center;
  }
  .descript {
    max-width: 382px;
    font-weight: 400;
    font-style: normal;
    font-family: 'Archivo';
    font-size: 16px;
    color: #738088;
    line-height: 139.8%;
    text-align: center;
  }
}
