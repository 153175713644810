.tooltip {
  position: relative;
  &.hover {
    &:hover {
      .content {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &.click {
    .content {
      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .content {
    position: absolute;
    background: #fff;
    z-index: 1;
    border-radius: 4px;
    padding: 5px 10px;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    &.right {
      left: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
      &::before {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -6px;
        z-index: -1;
      }
    }
    &.top {
      bottom: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
      &::before {
        content: '';
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #fff;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -6px;
        z-index: -1;
      }
    }
    &.bottom {
      top: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
      &::before {
        content: '';
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -6px;
        z-index: -1;
      }
    }
    &.left {
      right: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
      &::before {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -6px;
        z-index: -1;
      }
    }
  }
}
