.tabs {
  .tabs-head {
    position: relative;
    .tab-item {
      .active {
        border-top: 4px solid #dc3571;
      }
    }
  }

  .tabs-content {
  }
}
