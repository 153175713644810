.filterForm {
  animation: open 0.3s ease-in-out;
  box-shadow: 0px 2px 6px 2px rgba(199, 205, 231, 0.2);
  @keyframes open {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .range {
    input {
      width: 100%;
    }
  }
}

.sort {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 12px;
  width: 220px;
  box-shadow: 0px 2px 6px 2px rgba(199, 205, 231, 0.2);
  animation: open 0.3s ease-in-out;

  @keyframes open {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  div {
    display: flex;
    align-items: center;
    border-radius: 6px;
    input {
      width: auto;
      cursor: pointer;
    }
    &:hover {
      background: #f0f2fa;
    }
    label {
      display: inline-block;
      flex: 1;
      padding: 8px 3px;
      width: 175px;
      cursor: pointer;
    }
  }
}
