.sidebar {
  width: 280px;
  padding: 0 15px;
  transition: all 0.3s ease-in-out;
  &.sidebar-close {
    width: 110px;
    .collapse {
      svg {
        transform: rotateY(180deg);
      }
    }
    .sidebar-menu {
      width: 80px;
      > ul {
        > li {
          padding: 13px 27px;
          .item {
            img {
              width: 25px;
              height: 25px;
            }
            span {
              opacity: 0;
              visibility: hidden;
            }
          }
          > ul {
            opacity: 0;
            visibility: hidden;
            height: 0;
            margin: 0;
          }
          > svg {
            opacity: 0;
            visibility: hidden;
          }
        }
      }
    }
  }
  .sidebar-menu {
    overflow-y: auto;
    overflow-x: hidden;
    background: #ffffff;
    box-shadow: 3px 0px 4px rgba(234, 234, 234, 0.25);
    position: relative;
    height: 70%;
    margin: 40px 0;
    border-radius: 16px;
    position: fixed;
    width: 250px;
    padding: 15px 0;
    transition: all 0.3s ease-in-out;
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
      }
    }
    > ul {
      > li {
        padding: 13px 30px;
        cursor: pointer;
        position: relative;
        &.active {
          > ul {
            display: block;
          }
        }
        > ul {
          margin-top: 12px;
          display: none;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -30px;
            right: -30px;
            height: calc(100% + 13px);
            background: #fff;
          }
          > li {
            padding: 12px 0 12px 33px;
            color: #949494;
            font-weight: 600;
            font-size: 14px;
            position: relative;
            white-space: nowrap;
            &.active {
              color: var(--common-primaryColor);
            }
          }
        }
        > svg {
          position: absolute;
          right: 20px;
          top: 14px;
          font-size: 22px;
          color: #777777;
          transition: all 0.3s ease-in-out;
        }
        &.active {
          background: var(--common-secondaryColor);
          &::before {
            content: '';
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
            width: 3px;
            background: var(--common-primaryColor);
          }
          .item {
            span {
              color: #181818;
            }
          }
        }
        .item {
          display: flex;
          align-items: center;
          height: 25px;
          img {
            width: 22px;
            margin-right: 12px;
            transition: all 0.3s ease-in-out;
            transition-delay: 0.1s;
          }
          span {
            color: #949494;
            font-weight: 600;
            font-size: 15px;
            transition: all 0.3s ease-in-out;
            transition-delay: 0.1s;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .collapse {
    padding: 6px 16px;
    box-shadow: 0px 1.5px 4px 0px #9f9ce84d;
    width: fit-content;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 15px;
    right: 15px;
    cursor: pointer;
    color: #999999;
    font-size: 18px;
    svg {
      transition: all 0.3s ease-in-out;
    }
  }
}
