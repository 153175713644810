.table-wrapper {
  display: flex;
  flex-direction: column;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .title {
    color: #181818;
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
  }

  .actions {
    display: flex;
    flex-direction: row-reverse;
    button {
      font-family: 'Inter', sans-serif;
    }
    .secondary {
      margin-right: 8px;
    }
  }
}

.table {
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  overflow: hidden;
  display: table;
  width: 100%;
  position: relative;
  &.medium {
    border: none;
    background: #fff;
    .table-head {
      background: #e3e8ec;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
    }
    .table-body {
      &:hover {
        background: #fafafa !important;
      }
      &:nth-child(odd) {
        background: #ffffff;
      }
    }
  }
  &-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.loading {
    .table-head,
    .table-body {
      opacity: 0.6;
      pointer-events: none;
    }
  }
  &-head {
    display: table-row;
    width: 100%;
    background: #f8f9fd;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    &-item {
      padding: 12px 16px;
      display: table-cell;
      vertical-align: top;
      border: 1px solid #f0f2fa;

      &.left {
        text-align: left;
      }
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }
      &.selection {
        width: 40px;
        input {
          margin: 0;
        }
      }
    }
  }
  &-body {
    width: 100%;
    display: table-row;
    &-item {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      padding: 16px;
      display: table-cell;
      vertical-align: top;
      border: 1px solid #f0f2fa;

      &.selection {
        &.left {
          text-align: left;
        }
        &.center {
          text-align: center;
        }
        &.right {
          text-align: right;
        }
        input {
          margin: 0;
        }
      }
    }
  }
}

.custom-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 16px;
  height: 16px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 16px;
    height: 16px;
    z-index: 1;
  }
  .checkmark-head {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid #d1d1d1;
    border-radius: 3px;
    background: #fff;
    &::after {
      content: '';
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      background-color: #dc3571;
      border-radius: 2px;
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid #d1d1d1;
    border-radius: 3px;
    background: #fff;
    &::after {
      content: '';
      position: absolute;
      display: none;
      left: 5px;
      top: 2px;
      width: 5px;
      height: 8px;
      border: solid#DC3571;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  input:checked ~ .checkmark-head {
    background: #fdeff4;
    border-color: #fdeff4;
  }
  input:checked ~ .checkmark {
    background: #fdeff4;
    border-color: #fdeff4;
  }
  input:checked ~ .checkmark-head:after {
    display: block;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
}
.custom-radio {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 16px;
  height: 16px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 16px;
    height: 16px;
    z-index: 1;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid #d1d1d1;
    border-radius: 50px;
    background: #fff;
    &:after {
      content: '';
      position: absolute;
      display: none;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #dc3571;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  input:checked ~ .checkmark {
    background: #fff;
    border-color: #dc3571;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
}
