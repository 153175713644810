.dropdown {
  cursor: pointer;
  position: relative;
  .trigger {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 14px;
    z-index: 1;
    span {
      margin-right: 4px;
    }
  }
  .overlay {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s ease-in-out;
    z-index: 999;
    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
}

.dropdown-overlay {
  padding: 4px 0;
  background: #ffffff;
  display: flex;
  box-shadow: 0px 3px 8px 0px #e1e1e4;
  z-index: -1;
  min-width: 80px;
  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    li {
      list-style: none;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      &:hover {
        background: #d7d7d755;
      }
      span {
        font-size: 14px;
      }
    }
  }
}

.overlay-bottom {
  left: -4px;
  top: 8px;
  ul {
    li {
      display: flex;
      justify-content: flex-start;
      span {
        text-align: left;
      }
    }
  }
}

.overlay-top {
  left: -4px;
  bottom: 32px;
  ul {
    li {
      display: flex;
      justify-content: flex-start;
      span {
        text-align: left;
      }
    }
  }
}

// .overlay-hide {
//   display: none;
// }
