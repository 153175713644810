.edit-profile {
  input[type='text'] {
    border: none;
    outline: none;
    background-color: #f8f9fd !important;
    padding: 10px 16px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    transition: all 0.3s;
  }
  input[type='text']:focus {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    transition: all 0.3s;
  }
  textarea {
    border: none;
    outline: none;
    background-color: #f8f9fd !important;
    border-radius: 8px;
    padding: 14px 16px;
    max-width: 400px;
    width: 100%;
    transition: all 0.3s;
  }
  textarea:focus {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    transition: all 0.3s;
  }
}
