.header {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(214, 224, 239, 0.25);
  padding: 8px 0;
  height: 65px;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 1000;
  position: fixed;
  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1470px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }
  .logo {
    width: 280px;
    img {
      max-width: 100%;
      max-height: 50px;
    }
  }
  .left {
    display: flex;
    align-items: center;
  }
  .right {
    display: flex;
    align-items: center;
  }
}

.mobile-menu {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  width: 100%;
  transition: all 0.2s ease-in-out;
  border: none;
  top: 0;
  height: 100vh;
  background: #000000bb;
  .menu-box {
    padding: 24px 0 0;
    background: white;
    display: flex;
    flex-direction: column;
  }
  .tab-active {
    background: #fff3f6;
    padding: 16px 32px;
    display: flex;
    align-items: center;
    span {
      font-size: 18px;
      margin-left: 12px;
      font-weight: 600;
      color: #181818;
    }
  }
  .tab-inactive {
    background: white;
    padding: 16px 32px;
    display: flex;
    align-items: center;
    span {
      font-size: 18px;
      margin-left: 12px;
      font-weight: 600;
      color: #949494;
    }
  }
  .danger {
    margin-top: 16px;
    padding: 24px 32px 32px;
    background: #333333;
    display: flex;
    flex-direction: column;
    p {
      margin: 16px 0 0;
      font-size: 16px;
      color: #e7e7e7;
      span {
        font-weight: 600;
      }
    }
  }
}

.menu-show {
  opacity: 1;
  visibility: visible;
  top: 59px;
}
