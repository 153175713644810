.download {
  box-shadow: 4px 6px 16px 8px rgba(199, 205, 231, 0.32);
  .content {
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f8f9fd;
    }
    &::-webkit-scrollbar-thumb {
      background: #ccd1e0;
      border-radius: 99px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
