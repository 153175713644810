@import './variables';
@import './colors';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  z-index: 1001;
  transition: 0.2s ease-in-out;
  &.visible {
    opacity: 1;
    visibility: visible;
    .mark {
      opacity: 1;
      visibility: visible;
    }
    .content {
      opacity: 1;
      visibility: visible;
      transform: translateX(-50%) scale(1);
    }
    &.centered {
      .content {
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
  .mark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    transition: 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }
  .content {
    max-width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scale(0.7);
    border: 1px solid;
    border-color: $modal-border-color-default;
    border-radius: $modal-border-radius-default;
    background-color: $modal-bg-color-default;
    transition: 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    .titleWrapper {
      padding: 12px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .close {
      cursor: pointer;
      font-size: 20px;
      height: 20px;
      color: #949494;
    }
    .title {
      font-weight: $modal-title-fontweight-default;
      color: $modal-title-color-default;
      font-size: $modal-title-fontsize-default;
      margin-bottom: 0;
    }
    .description {
      font-weight: $modal-desc-fontweight-default;
      font-size: $modal-desc-fontsize-default;
      color: $modal-description-color-default;
      line-height: $modal-desc-lineheight-default;
    }
  }
  &.centered {
    .content {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) scale(0.7);
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px;
  }
}

.childrenWrapper {
  padding: 16px 24px 24px;
}
