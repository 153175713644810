.dropdown-custom {
  position: relative;
  .dropdown-content {
    display: none;
    position: absolute;
    color: #131313;
    &.visible {
      display: block;
    }
    &.bottom {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
    &.bottom-left {
      top: 100%;
      left: 0;
    }
    &.bottom-right {
      top: 100%;
      right: 0;
    }
    &.top {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
    &.top-left {
      bottom: 100%;
      left: 0;
    }
    &.top-right {
      bottom: 100%;
      right: 0;
    }
  }
}
