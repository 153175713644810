.process-api {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 1001;
  .icon {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 4px 8px 4px rgba(199, 205, 231, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &.running {
      &::before {
        content: '';
        cursor: pointer;
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border-radius: 50%;
        border-top: 1px solid #dc3571;
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
        border-left: 1px solid #fff;
        animation: loadingR 1.5s linear infinite;
      }
    }
    .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .loader-x {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        border: 5px solid;
        animation: animloader 1s linear infinite alternate;
      }
    }
    > svg {
      cursor: pointer;
    }
    .ic-ani {
      width: 35px;
      height: 35px;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      background: #dc3571;
      color: #eef0f5;
      position: absolute;
      transform: translate(0px, -60px);
      font-size: 20px;
      animation: Animate 1.6s forwards ease;
    }
    > span {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      font-size: 12px;
      color: #fff;
      background: #dc3571;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .process-wap {
    background: #ffffff;
    box-shadow: 4px 6px 16px 8px rgba(199, 205, 231, 0.32);
    border-radius: 16px;
    padding: 12px;
    width: 280px;
    .header-tab {
      display: flex;
      align-items: center;
      position: relative;
      padding-bottom: 12px;
      &::before {
        content: '';
        height: 1px;
        position: absolute;
        bottom: 0;
        left: -16px;
        right: -16px;
        background: #eef0f5;
      }
      .tab-item {
        border: 1px solid #e6e9f1;
        border-radius: 60px;
        background: #fff;
        font-weight: 600;
        font-size: 14px;
        color: #949494;
        padding: 6px 16px;
        margin-left: 8px;
        cursor: pointer;
        &.active {
          background: #f0f0f0;
          border-color: #f0f0f0;
          color: #333;
        }
        &:hover {
          color: #333;
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
    .process-list {
      display: none;
      min-height: 300px;
      .no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
        font-weight: 500;
        color: #9e9e9e;
      }
      &.show {
        display: block;
      }
      .box {
        max-height: 300px;
        overflow-y: auto;
        padding-top: 12px;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: #d1d1d1;
          border-radius: 10px;
        }
      }
      &::before {
        content: '';
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #ffffff;
        position: absolute;
        bottom: -10px;
        right: 20px;
      }
      .item {
        border: 1px solid #eef0f5;
        border-radius: 12px;
        padding: 12px 16px;
        margin-bottom: 10px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        position: relative;
        &:hover {
          .item-close {
            display: block;
          }
        }
        .item-close {
          position: absolute;
          top: -10px;
          right: 6px;
          cursor: pointer;
          font-size: 18px;
          color: #555;
          background: #fff;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          display: none;
        }
        &.finish {
          background: #eef9e8;
          border-color: #69bc41;
          .item-status {
            color: #429d15;
          }
        }
        &.failed {
          background: #ffefef;
          border-color: #ffacac;
          .item-status {
            color: #fa3c3c;
            span {
              color: #181818;
              &::before {
                color: #fa3c3c;
              }
            }
          }
        }
        // &:hover {
        //   background: #f2f3f6;
        //   border-color: #f2f3f6;
        // }
        &-method {
          color: #738088;
          font-size: 13px;
          margin-bottom: 4px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &-title {
          font-weight: 600;
          font-size: 14px;
        }
        &-status {
          font-weight: 400;
          font-size: 13px;
          color: #dc3571;
          .loading {
            display: flex;
            .letter {
              animation: loadingF 1.6s infinite linear;
              &.l-1 {
                animation-delay: 0.48s;
              }
              &.l-2 {
                animation-delay: 0.6s;
              }
              &.l-3 {
                animation-delay: 0.72s;
              }
              &.l-4 {
                animation-delay: 0.84s;
              }
              &.l-5 {
                animation-delay: 0.96s;
              }
              &.l-6 {
                animation-delay: 1.08s;
              }
              &.l-7 {
                animation-delay: 1.2s;
              }
              &.l-8 {
                animation-delay: 1.32s;
              }
              &.l-9 {
                animation-delay: 1.44s;
              }
              &.l-10 {
                animation-delay: 1.56s;
              }
            }
          }
          span {
            margin-left: 14px;
            position: relative;
            &::before {
              content: '';
              position: absolute;
              width: 2px;
              height: 2px;
              border-radius: 50%;
              background: #dc3571;
              left: -9px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
        &-free {
          display: flex;
          align-items: center;
          color: #738088;
          font-size: 12px;
          margin-bottom: 2px;
          img {
            margin-right: 7px;
            max-width: 15px;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.process-tooltip {
  > div > div:first-child {
    transform: translateY(100%);
    right: 30px;
    left: auto;
  }
}
.modal-result {
  min-height: 300px;
  .modal-loading {
    min-height: 300px;
    display: flex;
    align-items: center;
  }
  .info-service {
    border: 1px solid #e6e9f1;
    border-radius: 12px;
    padding: 20px;
    .info-item {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
      &:last-child {
        padding-top: 15px;
        border-top: 1px dashed #e6e9f1;
      }
      .item-text {
        color: #738088;
        font-weight: 600;
        font-size: 14px;
        width: 150px;
      }
      .item-value {
        color: #181818;
        font-size: 14px;
        &.item-status {
          background: #eef9e8;
          padding: 4px 10px;
          border-radius: 32px;
          color: #429d15;
          font-weight: 600;
          font-size: 12px;
        }
      }
    }
  }
  .result-service {
    border: 1px solid #e6e9f1;
    border-radius: 12px;
    padding: 20px;
    margin-top: 15px;
    margin-bottom: 20px;
    .result-head {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .result-title {
        font-weight: 600;
        font-size: 14px;
        color: #181818;
      }
      .result-collapse {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #949494;
        font-size: 13px;
        margin-left: 15px;
        svg {
          margin-right: 4px;
        }
      }
    }
    .result-content {
      max-height: 250px;
      overflow-y: auto;
      font-size: 14px;
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #d1d1d1;
        border-radius: 10px;
      }
    }
  }
}

@keyframes Animate {
  0% {
    transform: translate(0px, -60px) scale(1);
  }
  100% {
    transform: translate(0px, 0px) scale(0);
  }
}

@keyframes loadingF {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loadingR {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

$lite: #9b9b9b;
@keyframes animloader {
  0% {
    border-color: rgba($lite, 1) rgba($lite, 0) rgba($lite, 0) rgba($lite, 0);
  }
  33% {
    border-color: rgba($lite, 1) rgba($lite, 1) rgba($lite, 0) rgba($lite, 0);
  }
  66% {
    border-color: rgba($lite, 1) rgba($lite, 1) rgba($lite, 1) rgba($lite, 0);
  }
  100% {
    border-color: rgba($lite, 1) rgba($lite, 1) rgba($lite, 1) rgba($lite, 1);
  }
}
