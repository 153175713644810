.first-login {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  .box {
    display: flex;
    height: 100%;
    .left {
      width: 50%;
      display: flex;
      align-items: center;
      padding-left: 10%;
      background-repeat: no-repeat;
      background-size: cover;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .welcome {
        color: #ffffff;
        font-weight: 700;
        font-size: 54px;
        line-height: 1.2;
      }
      .text {
        margin-top: 20px;
        color: #d0c4f7;
        font-weight: 400;
        font-size: 16px;
      }
    }
    .right {
      width: 50%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      .content {
        max-width: 421px;
      }
      .head {
        font-weight: 600;
        font-size: 36px;
        line-height: 1;
        padding-bottom: 30px;
        border-bottom: 1px solid #edecfb;
      }
      .avatar {
        display: flex;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 20px;
        .box-avatar {
          width: 75px;
          height: 75px;
          border-radius: 50%;
          background: #d0c4f7;
          position: relative;
          &:hover {
            .cancel {
              display: block;
            }
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
          .cancel {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            background: rgba(255, 255, 255, 0.7);
            border: none;
            outline: none;
            font-size: 12px;
            border-radius: 4px;
            display: none;
          }
        }
        .box-text {
          padding-left: 20px;
          .txt {
            font-weight: 600;
            font-size: 16px;
            line-height: 1;
          }
          .edit {
            span {
              color: #3c8ff8;
              font-weight: 600;
              font-size: 12px;
              cursor: pointer;
              &:hover {
                opacity: 0.9;
              }
            }
          }
        }
      }
      .footer {
        padding-top: 10px;
        button {
          outline: none;
          box-shadow: none;
          font-weight: 600;
          height: 44px;
          border-radius: 6px;
          padding: 6px 15px;
          cursor: pointer;
          width: 100%;
          font-size: 16px;
          &:hover {
            opacity: 0.8;
          }
          &.cancel {
            border: 1px solid #d0d0d0;
            color: #181818;
            background: #fff;
          }
          &.submit {
            border: 1px solid #4a45d4;
            color: #fff;
            background: #4a45d4;
          }
        }
      }
      :global(.ant-form-item-label > label) {
        color: #181818;
        font-weight: 600;
        font-size: 15px;
      }
      :global(.ant-input) {
        border: 1px solid #e8e8e8;
        border-color: #e8e8e8 !important;
        height: 40px;
        outline: none;
        box-shadow: none;
        border-radius: 6px;
        font-size: 15px;
        padding: 10px 15px;
      }
      :global(textarea.ant-input) {
        height: auto;
        max-width: 600px;
      }
      :global(
          .ant-select-single
            .ant-select-selector
            .ant-select-selection-placeholder
        ) {
        line-height: 40px;
      }
      :global(
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector
            .ant-select-selection-search-input
        ) {
        height: 40px;
      }
      :global(
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector
        ) {
        border: 1px solid #e8e8e8;
        border-color: #e8e8e8 !important;
        height: 40px;
        outline: none !important;
        box-shadow: none !important;
        border-radius: 6px;
        font-size: 15px;
        padding-left: 15px;
      }
      :global(.ant-select) {
        width: 100%;
        height: 40px;
      }
      :global(
          .ant-select-single .ant-select-selector .ant-select-selection-item
        ) {
        line-height: 40px;
      }
      :global(.ant-checkbox-checked .ant-checkbox-inner) {
        background-color: #ecf4fe;
        border-color: #ecf4fe;
      }
      :global(.ant-checkbox-wrapper:hover .ant-checkbox-inner) {
        border-color: #ecf4fe;
      }
      :global(.ant-checkbox:hover .ant-checkbox-inner) {
        border-color: #ecf4fe;
      }
      :global(.ant-checkbox-checked::after) {
        border-color: #ecf4fe;
      }
      :global(.ant-checkbox-input:focus + .ant-checkbox-inner) {
        border-color: #ecf4fe;
      }
      :global(.ant-checkbox-checked .ant-checkbox-inner::after) {
        border-color: #3073c9;
      }
    }
  }
}
