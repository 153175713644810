.header {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(214, 224, 239, 0.25);
  padding: 8px 0;
  position: fixed;
  height: 65px;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 1000;
  .breadcrumb {
    ul {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      li {
        list-style: none;
        display: flex;
        align-items: center;
        color: #b2b2b2;
        font-size: 15px;
        font-weight: 500;
        &:last-child {
          color: #181818;
          pointer-events: none;
        }
        span {
          margin: 0 5px;
          color: #b2b2b2;
        }
        div {
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 160px;
        }
      }
    }
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1470px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }
  .logo {
    width: 280px;
    img,
    svg {
      max-width: 100%;
      max-height: 50px;
    }
  }
  .left {
    display: flex;
    align-items: center;
  }
  .right {
    display: flex;
    align-items: center;
  }
  .saved {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 4px rgba(192, 176, 177, 0.25);
    border-radius: 50%;
    margin-right: 20px;
  }
  .user {
    &-info {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        object-fit: cover;
      }
      > span {
        font-weight: 600;
        font-size: 16px;
        color: #181818;
        margin-left: 12px;
      }
      .icon-down {
        width: 8px;
        height: 8px;
        border-right: 2px solid #777;
        border-bottom: 2px solid #777;
        transform: rotate(45deg);
        margin-left: 10px;
        margin-top: -3px;
      }
    }
    .function {
      cursor: initial;
      padding: 16px;
      min-width: 310px;
      background: #ffffff;
      box-shadow: 0px 4px 4px 0px #9ba6b740;
      border-radius: 0px 0px 16px 16px;
      .box-function {
        background-color: var(--common-tertiaryColor);
        border-radius: 12px;
        padding: 16px 16px 24px;
        position: relative;
        overflow: hidden;
        > svg,
        img {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          object-fit: contain;
          height: 465px;
          opacity: 20%;
        }
        .box-content {
          position: relative;
          z-index: 1;
          .blance {
            display: flex;
            justify-content: space-between;
            .money {
              font-weight: 600;
              font-size: 20px;
              color: #fff;
              p {
                margin-bottom: 2px;
                font-weight: 600;
                font-size: 12px;
                color: rgba(255, 255, 255, 0.8);
              }
            }
            .plus {
              background: rgba(255, 255, 255, 0.1);
              width: 40px;
              height: 40px;
              border-radius: 50%;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              font-size: 25px;
              cursor: pointer;
            }
          }
          .fc-menu {
            display: flex;
            align-items: center;
            margin-top: 20px;
            cursor: pointer;
            img {
              width: 20px;
              height: 20px;
              object-fit: contain;
            }
            span {
              margin-left: 8px;
              font-weight: 600;
              font-size: 14px;
              line-height: 140%;
              color: white;
            }
          }
          .wallet {
            background: rgba(255, 255, 255, 0.2);
            border-radius: 8px;
            padding: 8px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            margin-top: 15px;
            span {
              color: white;
              font-size: 14px;
            }
            img {
              width: 17px;
              height: 17px;
              object-fit: contain;
            }
          }
        }
      }
      .footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
        .logout {
          display: inline-flex;
          align-items: center;
          font-weight: 600;
          font-size: 14px;
          color: #181818;
          cursor: pointer;
          span {
            margin-left: 7px;
          }
          &:hover {
            opacity: 0.7;
          }
        }
        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
    }
  }
  .switch-project {
    margin-right: 20px;
    .box-icon {
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(192, 176, 177, 0.25);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
    .switch-content {
      width: 260px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgb(155 166 183 / 25%);
      border-radius: 0px 0px 16px 16px;
      padding: 16px 20px;
      .switch-title {
        font-weight: 600;
        font-size: 16px;
        color: #181818;
        margin-bottom: 12px;
      }
      .switch-footer {
        padding: 18px 0 4px 0px;
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid #eef0f5;
        margin-top: 8px;
        a {
          display: inline-flex;
          align-items: center;
          color: #3c8ff8;
          font-weight: 600;
          font-size: 14px;
          &:hover {
            opacity: 0.7;
          }
          img,
          svg {
            margin-left: 7px;
          }
        }
      }
      .project-list {
        .project-item {
          display: flex;
          align-items: center;
          width: 100%;
          cursor: pointer;
          position: relative;
          text-decoration: none;
          padding: 8px 0;
          &:hover {
            &::before {
              display: block;
            }
          }
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -8px;
            right: -8px;
            bottom: 0;
            background: #f2f3f6;
            border-radius: 2px;
            display: none;
          }
          .item-logo {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            position: relative;
            img,
            svg {
              max-width: 100%;
            }
          }
          .item-name {
            color: #181818;
            position: relative;
            font-weight: 400;
            font-size: 14px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
